// Experience images
import capgemini from "../assets/images/logos/capgemini-logo.png";

// Education images
import teclab from "../assets/images/logos/teclab-logo.png";
import soyhenry from "../assets/images/logos/soyHenry-logo.jpeg";
import instsc from "../assets/images/logos/santa-catalina-logo.png";

// Certifications images
import cert1 from "../assets/certificates/Certificado Full Stack Web Developer.png";
import cert2 from "../assets/certificates/Certificado Full Stack UTN.png";
import cert3 from "../assets/certificates/Certificado Front End.png";
import cert4 from "../assets/certificates/Certificado Fundamentos Agilidad.png";
import cert5 from "../assets/certificates/Git y GitHub.png";
import cert6 from "../assets/certificates/Oracle Next Education.png";
import cert7 from "../assets/certificates/Certificado SQL.png";
import cert8 from "../assets/certificates/Web Design for Web Developers.png";
import cert9 from "../assets/certificates/Solving Problems with Software.png";
import cert10 from "../assets/certificates/Meta - Version Control.png"
import cert11 from "../assets/certificates/Meta - JavaScript.png"
import cert12 from "../assets/certificates/Udemy - Clean Code.png"
import cert13 from "../assets/certificates/Udemy - TypeScript.png"
import cert14 from "../assets/certificates/AWS - Introduction AWS.png"
import cert15 from "../assets/certificates/Austral - Bootstrap.png"

export const experienceData = [
  {
    id: 1,
    name: "Capgemini",
    image: capgemini,
    page: "https://www.capgemini.com/ar-es/",
  },
];

export const educationData = [
  {
    id: 1,
    name: "Teclab",
    image: teclab,
    page: "https://teclab.edu.ar",
    date: "2023 - 2026",
  },
  {
    id: 2,
    name: "SoyHenry",
    image: soyhenry,
    page: "https://www.soyhenry.com",
    date: "2021 - 2022",
  },
  {
    id: 3,
    name: "Instituto Santa Catalina",
    image: instsc,
    page: "https://www.casasantacatalina.com.ar",
    date: "2016 - 2020",
  },
];

export const certificationData = [
  {
    name: "Certificado Full Stack Web Developer SoyHenry",
    url: "https://certificates.soyhenry.com/cert?id=e2153b0c-3749-4faa-9931-d006c9ea5fcc",
    img: cert1,
  },
  {
    name: "Certificado Full Stack UTN e-learning",
    url: "https://drive.google.com/file/d/1C9_B9pq1KgWJgjUOJN8qRVH-GjVEWMxf/view",
    img: cert2,
  },
  {
    name: "Certificado Front End Oracle Next Education",
    url: "https://app.aluracursos.com/degree/certificate/c8082d46-8c5c-4281-a659-e2b88d317113",
    img: cert3,
  },
  {
    name: "Certificado Fundamentos de Agilidad Oracle Next Education",
    url: "https://app.aluracursos.com/certificate/b2c272ac-6f42-4829-baf4-8b566ebf3b6c",
    img: cert4,
  },
  {
    name: "Certificado Git y GitHub Oracle Next Education",
    url: "https://app.aluracursos.com/certificate/699f7e52-9406-4799-8460-740a47721c4e",
    img: cert5,
  },
  {
    name: "Certificado Oracle Next Education",
    url: "https://app.aluracursos.com/program/certificate/d3fd56f6-f95f-4c26-98fa-4b547da83833",
    img: cert6,
  },
  {
    name: "Certificado SQL",
    url: "https://www.coursera.org/account/accomplishments/verify/Z9GXK9LH7N4M",
    img: cert7,
  },
  {
    name: "Certificado Web Design for Web Developers",
    url: "https://www.udemy.com/certificate/UC-410ff1d5-e936-4f2c-9459-8a32e7f2017c/",
    img: cert8,
  },
  {
    name: "Certificado Solving Problems with Software",
    url: "https://www.coursera.org/account/accomplishments/verify/HJEFJNYBPXYW?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course",
    img: cert9,
  },
  {
    name: "Certificado Version Control",
    url: "https://www.coursera.org/account/accomplishments/certificate/MD8T3Z5U62CX",
    img: cert10,
  },
  {
    name: "Certificado JavaScript",
    url: "https://coursera.org/share/e4516c1bd1c21599ddd8b1d41bd6ed42",
    img: cert11,
  },
  {
    name: "Certificado Clean Code",
    url: "https://www.udemy.com/certificate/UC-a94a1b11-1c9e-4822-9d8f-551cb04019b9",
    img: cert12,
  },
  {
    name: "Certificado TypeScript",
    url: "https://www.udemy.com/certificate/UC-0b02e644-b6aa-4fd6-98c3-ea46dabe1a43",
    img: cert13,
  },
  {
    name: "Certificado Introduccion AWS",
    url: "https://coursera.org/share/96c0833bd97182a204ca8b020218ca95",
    img: cert14,
  },
  {
    name: "Certificado Bootstrap",
    url: "https://coursera.org/share/1fb46d889ed7d991e4c83ef47ddae9ab",
    img: cert15,
  },
];
